.filter-box {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
}

.filter-header {
    text-align: center;
    margin-bottom: 10px;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.filter-title {
    font-size: 18px;
    font-weight: bold;
}

.filter-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.filter-column {
    flex: 1 1 100%;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .filter-column {
        flex: 1 1 calc(50% - 10px);
        margin-right: 10px;
        margin-bottom: 0;
    }
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

select,
button {
    width: 100%; /* Ancho completo */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    /* Aumentar el padding horizontal para hacer más ancho el botón */
    padding-left: 40px;
    padding-right: 40px;
}

button:hover {
    background-color: #0056b3;
}
