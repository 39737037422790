
.action-buttons {
  display: flex;
  justify-content: space-between; /* Alinea los botones horizontalmente con espacio entre ellos */
}

.view-button,
.delete-button {
  background-color: transparent;
  color: green;
  border: none;
  cursor: pointer;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Ajusta el margen entre los botones */
}

.delete-button {
  color: red;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-confirm {
  background-color: #28a745; /* Verde */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px; /* Ajusta el margen entre botones si es necesario */
}

.btn-cancel {
  background-color: #dc3545; /* Rojo */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}
