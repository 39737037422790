.report-indicadores-page {
    padding: 20px;
    max-width: 800px;
    font-family: Arial, sans-serif;
}

.filter-container {
    background-color: #ffffff; /* Fondo azul */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.filter-title {
    text-align: center;
    margin-bottom: 10px;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.consult-button {
    background-color: #0077cc; /* Color azul más oscuro */
    color: #ffffff; /* Letras blancas */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}
.result-container {
    width: 100%; /* Asegura que el contenedor tome el 100% del ancho disponible */
    overflow-x: auto; /* Permite el desplazamiento horizontal si la tabla es más ancha que el contenedor */
    padding: 20px; /* Añade algo de relleno para el contenedor */
    background-color: #ffffff; /* Fondo blanco */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.result-container table {
    width: 100%; /* Asegura que la tabla tome el 100% del ancho del contenedor */
    border-collapse: collapse; /* Colapsa los bordes de la tabla */
    table-layout: auto; /* Deja que las columnas se ajusten automáticamente */
}

.result-container th, .result-container td {
    padding: 12px 15px; /* Añade relleno a las celdas */
    text-align: left; /* Alinea el texto a la izquierda */
    border-bottom: 1px solid #ddd; /* Añade un borde inferior a las celdas */
}

.result-container th {
    background-color: #f2f2f2; /* Fondo gris claro para la cabecera */
    font-weight: bold; /* Texto en negrita para la cabecera */
}

.result-container tr {
    display: table-row; /* Asegura que las filas se muestren correctamente */
}

.result-container tbody {
    display: table-row-group; /* Asegura que el grupo de filas se muestre correctamente */
}

.result-container thead {
    display: table-header-group; /* Asegura que el grupo de cabecera se muestre correctamente */
}
.loading-message, .error-message, .no-data-message {
    text-align: center;
    font-size: 16px;
}

.loading-message {
    color: #333333;
}

.error-message {
    color: #ff0000;
}

.no-data-message {
    color: #999999;
}

/* Estilos para eliminar los bordes de la tabla */
table.no-border {
    border-collapse: collapse; /* Asegura que los bordes colapsen */
    width: 100%;
}

table.no-border td,
table.no-border th {
    border: none; /* Elimina los bordes de las celdas */
    padding: 8px; /* Ajusta el relleno según sea necesario */
}

/* Opcional: estilo para los encabezados */
table.no-border th {
    background-color: #0000FF; /* Fondo para los encabezados */
    color: white; /* Texto blanco */
    text-align: center; /* Alineación del texto centrada */
}
