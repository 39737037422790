.header {
    background: #283277;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: fixed;
    width: 100%;
    height: 50px; 
    top: 0;
    left: 0;
    z-index: 1000;
    transition: left 0.3s ease; /* Transición suave para el cambio de posición */
}

.header-left {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Margen izquierdo para separación del borde */
    width: 50px; /* Ancho fijo para el lado izquierdo */
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Alinear contenido a la derecha */
    width: calc(100% - 50px); /* Resta el espacio del lado izquierdo */
    /*margin-right: 10px; /* Margen derecho por defecto */
    transition: margin-right 0.3s ease; 
}

.header-right .with-sidebar {
    margin-right: 250px; /* Ajuste del margen derecho cuando el sidebar está visible */
}

.user-info {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.dropdown {
    position: relative;
}

.dropdown-toggle {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none; /* Ocultar el menú desplegable por defecto */
}

.dropdown-menu.show {
    display: block; /* Mostrar el menú desplegable cuando se active */
}

.dropdown-menu a {
    display: block;
    padding: 10px;
    color: #333;
    text-decoration: none;
}

.dropdown-menu a:hover {
    background: #f0f0f0;
}

.toggle-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
}
