.user-management-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.action-link,
.action-button {
  margin-right: 10px;
}

.filters {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
}

.table-container {
  margin-top: 20px;
}

.users-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-green {
  background-color: #28a745;
  color: white;
  border: none;
  margin-top: 10px; /* Añade un espacio entre el título y el botón */
}

.btn-green:hover {
  background-color: #218838;
}
