.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 250px; /* Ancho del sidebar */
    background-color: #283277; /* Color de fondo del sidebar */
    overflow-y: auto; /* Scroll vertical si es necesario */
    border-right: 1px solid #ddd; /* Borde derecho opcional */
    color: white; /* Color de las letras */
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
}

.sidebar-hidden {
    transform: translateX(-100%);
}

.logo {
    padding: 20px;
    text-align: center;
    background-color: transparent;/* Color de fondo del contenedor del logo */
  }
  
  /* Estilos para la imagen del logo */
  .logo img {
    max-width: 50%; /* Ajusta el ancho máximo de la imagen */
    height: auto; /* Permite que la altura se ajuste automáticamente según el ancho */
    display: block; /* Asegura que la imagen se muestre como bloque para controlar el espacio alrededor */
    margin: 0 auto; /* Centra horizontalmente la imagen dentro de su contenedor */
    background-color: transparent; /* Fondo transparente para la imagen */
  }

nav ul {
    list-style: none;
    padding: 0 0 0 20px; /* Añadir padding a la izquierda */
    width: 100%; /* Asegurarse de que el ul ocupe todo el ancho del sidebar */
}

nav ul li {
    margin-bottom: 10px;
}

nav ul li a, nav ul li span {
    text-decoration: none;
    color: white; /* Color de las letras */
    display: block;
    padding: 10px;
    transition: background-color 0.3s ease;
    text-align: left; /* Alinear el texto a la izquierda */
}

nav ul li a:hover, nav ul li span:hover {
    background-color: #ddd;
    color: #000;
}

/* Estilos para submenús */
nav ul ul {
    padding-left: 20px; /* Indentación para submenús */
}

nav ul ul li {
    margin-bottom: 5px;
}

nav ul ul li a {
    padding: 8px 10px;
}

.active {
    /* Estilos para el enlace activo */
    /*background-color: #000DF2ñ*/
     /* Mismo fondo que el sidebar */
    color: white; /* Color de las letras del menú activo */
    font-weight: bold;
}

.active-submenu {
    background-color: gray; /* Color gris para el elemento seleccionado del submenú */
    color: white; /* Color de las letras del submenú seleccionado */
}

/* Estilos responsive */
@media (max-width: 768px) {
    .sidebar {
        width: 200px; /* Ajustar el ancho del sidebar en pantallas pequeñas */
    }

    .logo img {
        max-width: 70%; /* Ajustar el tamaño de la imagen en pantallas pequeñas */
    }

    nav ul {
        padding: 0 0 0 10px; /* Ajustar el padding en pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .sidebar {
        width: 150px; /* Ajustar el ancho del sidebar en pantallas muy pequeñas */
    }

    .logo img {
        max-width: 60%; /* Ajustar el tamaño de la imagen en pantallas muy pequeñas */
    }

    nav ul li a, nav ul li span {
        padding: 8px; /* Ajustar el padding en pantallas muy pequeñas */
    }

    nav ul ul {
        padding-left: 10px; /* Ajustar la indentación del submenú en pantallas muy pequeñas */
    }
}
