/* src/assets/styles/ChangePasswordPage.css */
.change-password-page {
    /*display: flex;
    flex-direction: column;*/
    align-items: center;
    justify-content: center;
    /*height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;*/
  }
  /*
  .change-password-page h1 {
    margin-bottom: 20px;
  }*/
  