/* src/assets/styles/ChangePasswordForm.css */

.change-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff; /* Fondo blanco para toda la página */
}

.change-password-form {
  max-width: 400px; /* Ancho máximo del formulario */
  width: 100%;
  padding: 20px;
  background-color: #f8f9fa; /* Fondo gris para el formulario */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Centrar el texto dentro del formulario */
}

.change-password-form h2 {
  margin-bottom: 20px;
  text-align: center; /* Centrar el título */
}

.form-group {
  margin-bottom: 20px;
  text-align: left; /* Alineación del texto a la izquierda */
}

.form-group label {
  display: block;
  margin-bottom: 8px;
}

.form-group input {
  width: calc(100% - 20px); /* Ajuste del ancho del input */
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  margin-top: 20px;
  text-align: center; /* Centrar el botón */
}

.btn-submit {
  width: 100%; /* Ancho completo del botón */
  padding: 10px;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.field-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
}

.status-message {
  margin-top: 10px;
  font-weight: bold;
  text-align: center; /* Centrar el mensaje de estado */
}

.status-message.success {
  color: #28a745; /* Verde para mensajes de éxito */
}

.status-message.error {
  color: #dc3545; /* Rojo para mensajes de error */
}
