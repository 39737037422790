/* src/styles/ForgotPassword.css */

.forgot-password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.forgot-password-header-image {
  width: 100%; /* Ajusta el ancho de la imagen según sea necesario */
  max-width: 600px; /* Establece el máximo ancho de la imagen */
  margin-bottom: 20px;
}

.forgot-password-content {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.forgot-password-content h2 {
  margin-bottom: 20px;
}

.forgot-password-content p {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
}

.form-group input {
  width: calc(100% - 20px); /* Ajusta el ancho del input */
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Asegura que los botones estén alineados verticalmente */
}

.btn-generate,
.btn-cancel {
  padding: 8px 16px; /* Ajusta el padding del botón */
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  width: 48%; /* Asegura que ambos botones tengan el mismo ancho */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center; /* Asegura que el contenido del botón esté centrado */
  font-size: 1rem; /* Asegura que el tamaño del texto sea consistente */
}

.btn-generate {
  background-color: #007bff;
  color: white;
  border: none;
}

.btn-cancel {
  background-color: #dc3545;
  color: white;
  border: none;
}

.btn-cancel:hover {
  background-color: #c82333;
}

.field-error {
  color: red; /* Cambia el color del texto del mensaje de error a rojo */
  font-size: 0.875rem;
  margin-top: 4px;
}

@media (max-width: 600px) {
  .forgot-password-content {
    padding: 10px;
  }
}
