/* styles.css */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

label {
  display: inline-block; /* Para alinear correctamente el checkbox */
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input[type="text"],
select,
input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="checkbox"] {
  margin-left: 5px; /* Ajuste de espacio entre la etiqueta y el checkbox */
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  flex: 1;
  padding: 0 10px;
}

button[type="submit"],
button[type="button"] {
  display: inline-block;
  width: 48%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

button[type="button"] {
  background-color: #dc3545;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

button[type="button"]:hover {
  background-color: #c82333;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
}

.radio-button-group {
  display: flex;
  align-items: center;
}

.radio-button-group label {
  margin-left: 10px;
  font-weight: bold;
  color: #555;
}