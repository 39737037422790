.main-page {
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

.input-container {
  margin-bottom: 20px;
}

.dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.client-dropdown-container,
.period-container {
  flex: 1;
  margin-right: 10px; /* Añadido margen derecho para separación */

}

.save-button {
  background-color: green;
  color: white;
  width: 30%; /* Reducido el ancho del botón */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px; /* Añadido borde redondeado */
}

.alert {
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}

.alert-danger {
  color: red;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb; /* Ajustado color del borde */
}

.alert-success {
  color: green;
  background-color: #d4edda;
  border: 1px solid #c3e6cb; /* Ajustado color del borde */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #0000ff; /* Fondo para los encabezados */
  color: white; /* Texto blanco */
  text-align: center; /* Alineación del texto centrada */
  border: none;
}

input[type="number"] {
  width: 100%;
  padding: 8px; /* Añadido padding para mejorar el espacio del input */
  box-sizing: border-box; /* Ajuste para incluir padding en el ancho */
}

.upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.upload-button input[type="file"] {
  display: none; /* Hide the actual file input */
}
