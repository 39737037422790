.main-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ocupa toda la altura del viewport */
    max-width: max-content;
}



.header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #333;
    color: #fff;
    padding: 10px;
    z-index: 100; /* Asegura que el header esté sobre el contenido */
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #333;
    color: #fff;
    padding: 10px;
    z-index: 100; /* Asegura que el footer esté sobre el contenido */
}

.content {
    display: flex;
    flex: 1; /* El contenido principal ocupa todo el espacio restante */
    margin-top: 60px; /* Ajustar según la altura del header */
    margin-bottom: 40px; /* Ajustar según la altura del footer */
    overflow: hidden; /* Evita la barra de desplazamiento vertical en el cuerpo principal */
}

.sidebar {
    width: 250px;
    background: #f4f4f4;
    transition: transform 0.3s ease, width 0.3s ease;
    /*overflow-y: auto; /* Habilita la barra de desplazamiento solo si es necesario */
}

.main-content {


    flex: 1; /* El contenido principal ocupa todo el espacio disponible */
    padding: 20px; /* Ajusta según sea necesario */
    overflow-y: auto; /* Habilita la barra de desplazamiento solo si es necesario */
    width: 100%;
    transition: margin-left 0.3s ease; /* Animación de transición opcional */
}

.sidebar-hidden .sidebar {
    width: 0; /* Oculta el sidebar */
    transform: translateX(-100%); /* Mueve el sidebar fuera de la vista */
}

.sidebar-hidden .main-content {
    margin-left: 0; /* Sin margen cuando el sidebar está oculto */
}



/* Ajuste adicional para dropdown dentro del header */
.header .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 10px; /* Ajusta según sea necesario */
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: none; /* Oculto por defecto */
}

.header .dropdown-menu.active {
    display: block; /* Muestra el dropdown cuando es activo */
}
