.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ajusta la altura al 100% del viewport */
  }
  
  .login-form {
    width: 300px; /* Ancho del formulario */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .login-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .field-error {
    color: red; /* Cambia el color del texto del mensaje de error a rojo */
    font-size: 0.875rem;
    margin-top: 4px;
  }
  
  @media (max-width: 600px) {
    .login-form {
      width: 100%; /* Cambia el ancho del formulario en dispositivos pequeños */
      max-width: 300px; /* Establece un ancho máximo */
    }
  }
  